import * as React from "react";
import { Link } from "gatsby";

// styles
const pageStyles = {
  background: "#121212",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#BBB",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const contentStyles = {
  padding: "40px",
  textAlign: "center",
};

const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <div style={contentStyles} className="content">
        <h1 style={{ fontWeight: 600 }}>404</h1>
        <Link
          style={{ color: "#FFF", marginTop: "16px", display: "block" }}
          to="/"
        >
          Go home
        </Link>
      </div>
    </main>
  );
};

export default NotFoundPage;
